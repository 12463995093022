import CustomModal from '../../../../../shared/CustomModal';
import ClientesSalesProductsTable from '../table';

const ClientSalesProductsModal = (props) => {
  return (
    <CustomModal open={props.open} setOpen={props.setOpen} title="Produtos da venda">
        <ClientesSalesProductsTable sale={props.sale} />
    </CustomModal>
  )
}

export default ClientSalesProductsModal;