import axiosInstance from "../../config/axio.config"; 
const reasons = '/fr';

export const reasonsRequestsFn = async (reqBody) => {
    try {
      const response = await axiosInstance.get(reasons, { params: reqBody });
      return response?.data || []
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};
  
export const createReasonRequestFn = async (reqBody) => {
  try {
    const response = await axiosInstance.post(reasons, reqBody);
    return response
  } catch ({ error }) {
    throw new Error(error?.message)
  }
};

export const updateReasonRequestFn = async (reqBody) => {
  try {
    const response = await axiosInstance.put(reasons, reqBody);
    return response
  } catch ({ error }) {
    throw new Error(error?.message)
  }
};
