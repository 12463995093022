import { useQuery } from "react-query"
import CustomDiv from "../../../../../shared/CustomDiv"
import { CustomTable } from "../../../../../shared/Table" 
import { clientsSalesProductsFn } from "../../../../../../services/clients"

const ClientesSalesProductsTable = ({sale}) => {
  const { data: products, isLoading } = useQuery(["clientes-sales-products", sale], () => clientsSalesProductsFn({ id: sale }))

  const tableHead = [
    { id: "description", headItem: "Descrição" },
    { id: "qtd", headItem: "Quantidade" },
    { id: "total", headItem: "Valor" },
    { id: "manutention", headItem: "Manutenção" },
  ]

  const createData = (
    description,
    qtd,
    total,
    manutention
  ) => ({
    description,
    qtd,
    total: total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
    manutention: manutention.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
  })


  const rows = products && products?.map((product) => {
    return createData(
      product.DESCRICAO, 
      product.QTDE_SAI, 
      product.T_LIQ,
      product.VLR_MANUTENCAO
    )
  });

  return (
    <>
      <CustomDiv className="flex flex-col w-full !p-0">
        <CustomTable
          tableHead={tableHead}
          tableBody={rows}
          isLoading={isLoading}
        />
      </CustomDiv>
    </>
  )
}

export default ClientesSalesProductsTable;
