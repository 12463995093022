import axiosInstance from "../../config/axio.config"; 
const clients = '/client';
const gerente = '/gerente/clientes';
const vendas = '/gerente/vendas';
const requests = '/requests';

export const clientsFn = (reqBody) => {
    try {
      const response = axiosInstance.get(clients+'/all', { params: reqBody })
      return response
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};

export const clientFn = async (reqBody) => {
    try {
      const response =  await axiosInstance.get(clients+'/id', { params: reqBody })
      return response?.data;
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};

export const clientsFinanceDetailsFn = async (reqBody) => {
    try {
      const response = await axiosInstance.get(gerente+'/financeDetails', { params: reqBody })
      return response?.data;
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};

export const clientsSalesFn = async (reqBody) => {
  try {
    const response = await axiosInstance.get(vendas+'/GetVendaPorCliente', { params: reqBody })
    return response?.data;
  } catch ({ error }) {
    throw new Error(error?.message)
  }
};

export const clientsSalesProductsFn = async (reqBody) => {

  if (!reqBody.id) return;
  try {
    const response = await axiosInstance.get(vendas+'/GetVendaItens', { params: reqBody })
    return response?.data?.ItensDaVenda || [];
  } catch ({ error }) {
    throw new Error(error?.message)
  }
};
  
export const clientsRequestsResumeFn = async (reqBody) => {

  if (!reqBody.id || !reqBody.InitialDate || !reqBody.FinalDate) return;

  try {
    const response = await axiosInstance.get(requests+'/clientResume/reason', { params: reqBody })
    return response?.data || [];
  } catch ({ error }) {
    throw new Error(error?.message)
  }

};
  