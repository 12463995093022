import { Button,  Divider,  TextField,  useTheme } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { clientsRequestsResumeFn } from '../../../../services/clients';
import { useQuery } from 'react-query';
import Loading from '../../../shared/loading/loading';
import CustomPeriodSelect from '../../../shared/CustomPeriodSelect';
import { useState } from 'react';
import ClientRequestsByReason from './requestsByReason';
import { getHoursByMinutes } from '../../../../helpers';

const ClientRequestsDetails = (props) => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const { data: values, isLoading } = useQuery(["client-requests-details", props.client, startDate, endDate], 
                                        () => clientsRequestsResumeFn({
                                                                        id: props?.client, 
                                                                        InitialDate: dayjs(startDate).utc(true).format('YYYY-MM-DD'), 
                                                                        FinalDate: dayjs(endDate).utc(true).format('YYYY-MM-DD')
                                                                    }));

    return (
    <div className="grid grid-cols-1 gap-2 ">
        <Loading open={isLoading} />
        <Divider sx={{ color: useTheme().palette.primary.main }} className=" font-bold">
            Resumo de Atendimentos
        </Divider>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-1 md:grid-cols-1 mb-2 ">
            <CustomPeriodSelect  label="Periodo" startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
        </div>
        <Divider sx={{ color: useTheme().palette.secondary.main }} className=" font-bold"/>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2 mt-2"> 
            <TextField 
                size = "small"
                fullWidth
                value={Number(values && values[0]?.total_requests  || 0)} 
                label="Total de Atendimentos"   
            />
            <TextField 
                size = "small"
                fullWidth
                value={getHoursByMinutes(values &&  values[0]?.total_duration  || 0)} 
                label="Tempo Total de Atendimento" 
            />
        </div>
        <Divider sx={{ color: useTheme().palette.primary.main }} className=" font-bold">
            Tipo de Atendimentos mais solicitados
        </Divider>
        <ClientRequestsByReason values={values}/>

    </div>

  )
}

export default ClientRequestsDetails;