import InputMask from 'react-input-mask';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material'


const ClientDetails = (props) => {

    const handleChangeValues = (value) => {
        //Alteração só no gerente
    }

    const disabled = false; //Como o evento de alteração não tem nada

  return (
    <Grid container spacing={1} columns={ 12 } sx={{float: "center" }} >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
            <TextField
                size = "small"
                name="nome_fantasia"
                value={props?.values?.nome_fantasia}
                defaultValue=" "
                label="Nome Fantasia"
                type="text"
                onChange={handleChangeValues}
                fullWidth
                disabled={disabled}
            />                              
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="razao_social"
            value={props?.values?.razao_social}
            defaultValue=" "
            label="Razão Social"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}
        />                              
        </Grid>
        <Grid item xs={12} md={4} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            fullWidth
            label="Código Interno"
            type="number"
            name='id_gerente'
            disabled={disabled}
            value={props?.values?.id_gerente}
            onChange={handleChangeValues}
        /> 
        </Grid>  
        <Grid item xs={12} sm={4} md={4} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="cpf_cnpj"
            value={props?.values?.cpf_cnpj}
            defaultValue=" "
            label="CPF / CNPJ"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}
        />                              
        </Grid>
        <Grid item xs={12} sm={12} md={4}  marginBottom={1} marginTop={1}>
            <InputMask
                mask="(99) 99999-9999"
                maskChar=""
                onChange={handleChangeValues}
                value={props?.values?.phone_number}
                fullWidth
                disabled={disabled}
            >
                {() =>                     
                <TextField 
                    size = "small"
                    label="Número para contato" 
                    variant="outlined"  
                    name='phone_number'
                    fullWidth
                >
                </TextField> }
            </InputMask> 
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="address_street"
            value={props?.values?.address_street}
            defaultValue=" "
            label="Endereço"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}                
        />                              
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="address_number"
            value={props?.values?.address_number}
            defaultValue=" "
            label="Número"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}                
        />                              
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="address_neighborhood"
            value={props?.values?.address_neighborhood}
            defaultValue=" "
            label="Bairro"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}                
        />                              
        </Grid>  
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="address_CEP"
            value={props?.values?.address_CEP}
            defaultValue=" "
            label="CEP"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}                
        />                              
        </Grid> 
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="address_city"
            value={props?.values?.address_city}
            defaultValue=" "
            label="Cidade"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}                
        />                              
        </Grid> 
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="address_state"
            value={props?.values?.address_state}
            defaultValue=" "
            label="Estado"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}                
        />                              
        </Grid> 
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="address_complement"
            value={props?.values?.address_complement}
            defaultValue=" "
            label="Complemento"
            type="text"
            onChange={handleChangeValues}
            fullWidth
            disabled={disabled}                
        />                              
        </Grid>  
        <Grid item xs={12} md={4}  marginBottom={1} marginTop={1}>
        <FormControl fullWidth >
            <InputLabel id="status-label">Status</InputLabel>
            <Select
            size = "small"
            labelId="status-label"
            label="Status"
            name="status"
            value={props?.values?.status}
            onChange={handleChangeValues}
            inputProps={{ readOnly: disabled }}
            >
            <MenuItem value={'1 - ATIVO'}><em>1 - Ativo</em></MenuItem>
            <MenuItem value={'2 - CANCELADO'}><em>2 - Cancelado</em></MenuItem>
            <MenuItem value={'3 - SUSPENSO'}><em>3 - Suspenso</em></MenuItem>
            <MenuItem value={'4 - MAQUINAS'}><em>4 - Máquinas</em></MenuItem>
            <MenuItem value={'7 - SIRRUS'}><em>7 - Sirrus</em></MenuItem>
            </Select>
        </FormControl>
        </Grid>    
        <Grid item xs={12} md={4} marginBottom={1} marginTop={1}>
        <FormControl fullWidth>
            <InputLabel id="tipo-prioridade-label">Classificação</InputLabel>
            <Select
                size = "small"
                labelId="tipo-prioridade-label"
                label="Classificação"
                fullWidth
                name="priority"
                value={props?.values?.priority}   
                onChange={handleChangeValues}
                inputProps={{ readOnly: disabled }}
            >
                <MenuItem value={1}><em>Lista Padrão</em></MenuItem>
                <MenuItem value={2}><em>Lista Vip</em></MenuItem>
            </Select>
        </FormControl> 
        </Grid>   
        <Grid item xs={12} md={4} marginBottom={1} marginTop={1}>
        <FormControl fullWidth>
            <InputLabel id="tipo-atendimento-label">Tipo</InputLabel>
            <Select
                size = "small"
                labelId="tipo-atendimento-label"
                label="Tipo"
                fullWidth
                name="type_client"
                value={props.values?.type_client} 
                onChange={(e) => handleChangeValues(e)}
                inputProps={{ readOnly: disabled }}
            >
                <MenuItem value={0}><em>Automação</em></MenuItem>
                <MenuItem value={1}><em>Ponto</em></MenuItem>
                <MenuItem value={2}><em>Assistência</em></MenuItem>
                <MenuItem value={3}><em>Maker</em></MenuItem>
                <MenuItem value={4}><em>Site</em></MenuItem>
            </Select>
            </FormControl>  
        </Grid>                                                                      
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
        <TextField
            size = "small"
            name="alert_msg"
            value={props?.values?.alert_msg}
            label="Mensagem de Alerta"
            type="text"
            onChange={handleChangeValues}
            multiline
            fullWidth
            disabled={disabled}                
        /> 
        <FormControlLabel
            disabled={disabled}
            control={
            <Checkbox 
                size = "small"
                name={'show_alert'}
                checked={props?.values?.show_alert === 'Y'} 
                inputProps={{ 'aria-label': 'controlled' }} 
            />
            }
            label='Usar Mensagem de Alerta'
        />    
        <FormControlLabel
            disabled={disabled}
            control={
            <Checkbox 
                size = "small"
                name={'hour_technical'}
                checked={props?.values?.hour_technical === 'Y'} 
                inputProps={{ 'aria-label': 'controlled' }} 
                
            />
            }
            label='Cliente hora técnica'
        />                           
    </Grid>                             
  </Grid>
  )
}

export default ClientDetails;