import React, { useState,  useMemo } from 'react';
import Box from '@mui/material/Box';
import './RequestCard.css'
import {  Chip, Collapse, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LoadingFull  from '../../shared/loadingFull';
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import { useCryptoSessionStorage } from '../../../hooks/useCryptoSessionStorage';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');

const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';

const styleClientText = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
}

let url = process.env.REACT_APP_API+ "/requests/id";

const RequestCard = (props) => {

  const { getData } = useCryptoSessionStorage();
  const user = getData("user") || {};
  const [openDescription, setOpenDescription] = useState(false);
  const { getDataDirectly, loading} = useFetchData();


  const handleOpenRequest = (values) => {
    props.setRequest(values);
    props.setOpenRequest(true);
  }

  const getRequest = async() => {
    await getDataDirectly(url, {
        id: props.values.service_id
    },
    async (response) => {
      handleOpenRequest(response); 
    }
    )
  }

  const AddService = () => {
    let request = { ...props.values };
    request.creator_user = user?.id;
    request.user = (props.userFilter) ? props.userFilter : user?.id;
    request.sequence = null;
    request.description = '';
    request.status = 2;
    request.title = '';

    props.setRequest(request);
    props.SetOpenAddService(true);
  }

  const handleOpenTransfer = () => {
    props.setRequest(props.values);
    props.setTransfer(true);
    props.setOpenSelectUser(true);
  }

  const getColorPriority = () => {
    switch(props.values.priority) {
      case 1: return 'rgba(124, 123, 123, 0.61)';
      // case 1: return 'rgba(230, 75, 4, 0.61)';
      case 2: return 'rgba(240, 8, 8, 0.952)';
      default: return 'rgba(124, 123, 123, 0.61)';
    }
  }

  const getColorPriorityClient = () => {
    switch(props.values?.client_priority) {
      // case 3: return 'rgba(124, 123, 123, 0.61)';
      // case 2: return 'rgba(230, 75, 4, 0.61)';
      case 2: return 'rgba(240, 8, 8, 0.42)';
      // default: return 'rgba(124, 123, 123, 0.61)';
    }
  }

  const minutesDiff = useMemo(()=> {

    if(!props.values?.start_date) {
      return 0
    }
    
    const now = dayjs.tz().format();
    const start_date =  dayjs.tz(props.values?.start_date).format();

    let diff = dayjs.tz(start_date) - dayjs.tz(now);  
    let MinDiff = Math.floor((diff/1000)/60)
    return MinDiff;

  }, [props?.values.start_date])

  const formatTime = useMemo(() => {

    if(!props.values?.start_date) {
      return 'Não informado'
    }
    if(props.values?.status === 4) {
      return 'Concluído'
    }    

    let days = ~~(minutesDiff/1440);
    let hours = ~~((minutesDiff%1440)/60);
    let min_rest = ((minutesDiff%1440)%60);

    if(props.values?.status > 2){
      days = days *-1; 
      hours = hours * -1;
      min_rest = min_rest * -1; 
    }
    
    let day = (days !== 0) ? ((days === 1) ? `${days} dia, ` : `${days} dias, `) :  ''; 
    let hour = (hours !== 0) ? ((hours === 1) ? `${hours} hora e ` : `${hours} horas e `) :  '';  
    let minutes = `${min_rest} min`;

    if(minutesDiff > -5 && minutesDiff  < 2 && props.values?.status === 2){
      return 'Em breve'
    }

    return  day + hour + minutes;  
  }, [props.values?.start_date, props.values?.status, minutesDiff])


  const ProgressText = useMemo(()=> {
    switch (props.values.status) {
      case 1: return `Aguardando confirmação`;
      case 2: return (minutesDiff < 0) ? 'Atendimento em atraso.' : `Previsão para atendimento: ${formatTime}`;
      case 3: return `Atendimento iniciado a ${formatTime} `;
      case 4: return `Concluído em: ${props.values.conclusion_day}, às ${props.values.conclusion_hour}`;
      default: return '';
    }
  }, [props.values.status, props.values.conclusion_day, props.values.conclusion_hour, formatTime, minutesDiff])

  const HandleColorTime = useMemo( () => {
    
      if(props.values?.status > 2){
        return 'success'; 
      }
      if(minutesDiff < 0) {
        return 'error';
      } else 
      if(minutesDiff < 5) {
        return 'warning';
      } else {
        return 'success';
      }
    }, [minutesDiff, props.values?.status]
  
  )

  return (
      <Box className="cardRequest"
        onClick={() => setOpenDescription(!openDescription)}
        sx={{boxShadow: `5px 5px ${getColorPriority()}`}}
        title={(props.values?.client_priority === 2) ? 'Cliente Preferencial' : ''}
      >
        <LoadingFull open={loading} /> 
        <header  
          style={{draggable: 'true', 
                  backgroundColor: getColorPriorityClient(),
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                }}>
          <Box className='cardRequest-Id' >
            <p><b>#{props.values.id}.{props.values?.sequence}</b> </p> 
          </Box>
          <Box className='cardRequest-client'>
            <Typography 
              variant='h7'
              sx={styleClientText}
            >{props.values.nome_fantasia}
            </Typography>
          </Box>
          <Box className="iconsHeader" sx={{display: (props.admin) ? '' : 'none'}}>
            <Tooltip title='Adicionar Serviço' color='white' >
              <IconButton onClick={AddService} >
                <PostAddIcon />
              </IconButton>
            </Tooltip>   
            <Tooltip title='Transferir Atendimento' color='white' >
              <IconButton onClick={handleOpenTransfer} >
                <PersonSearchIcon />
              </IconButton>
            </Tooltip> 
            <Tooltip title='Visualizar Atendimento' color='white' >
              <IconButton onClick={getRequest} >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>                 
          </Box>
        </header>
        <Box className='cardRequest-title'>
        <Typography fontSize={"0.8rem"}>{props.values.title}</Typography>
        </Box>
        <Collapse className='cardRequest-description' in={openDescription} timeout="auto" unmountOnExit>
          <Typography>{props.values.description}</Typography>
        </Collapse> 
        <divider/>
        <Box className="cardRequest-time-await" >
        <Tooltip title={ProgressText} placement="bottom">
          <Stack  direction={'row'} >
            <Chip
              sx={{height: '20px', border: '', borderRadius: '5px', m: 1}}
              color={HandleColorTime}
              icon={<AccessTimeIcon sx={{height: '19px'}}/>}
              label={formatTime}
              variant="outlined"
              onClick={() => {}}
            />
          </Stack>

        </Tooltip>
        </Box>
    </Box>
   
  )
}

export default RequestCard;