import { getHoursByMinutes } from "../../../../../helpers"
import CustomDiv from "../../../../shared/CustomDiv"
import { CustomTable } from "../../../../shared/Table"

const ClientRequestsByReason = ({values}) => {

  const tableHead = [
    { id: "description", headItem: "Descrição" },
    { id: "total", headItem: "Quantidade" },
    { id: "time", headItem: "Tempo" },
  ]

  const createData = (
    description, 
    total,
    time
  ) => ({
    description,
    total,
    time
  })


  const rows = values && values?.slice(0, 10)?.map((reason) => {
    return createData(
        reason.description,
        reason.total,
        getHoursByMinutes(reason.total_duration_type_service)
    )
  });

  return (
    <>
      <CustomDiv className="flex flex-col w-full !p-0">
        <CustomTable
          tableHead={tableHead}
          tableBody={rows}
        />
      </CustomDiv>
    </>
  )
}

export default ClientRequestsByReason
