import { Button,  Divider,  TextField,  useTheme } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { clientsFinanceDetailsFn } from '../../../../services/clients';
import { useQuery } from 'react-query';
import Loading from '../../../shared/loading/loading';
import CustomMonetaryInput from '../../../shared/CustomMonetaryInput';
import ClientesSalesTable from '../sales';


const ClienteFinanceDetails = (props) => {

    const { data: values, isLoading } = useQuery(["client-finance-details", props.client], () => clientsFinanceDetailsFn({id: props?.client}));

    return (
    <div className="grid grid-cols-1 gap-2 ">
        <Loading open={isLoading} />
        <Divider sx={{ color: useTheme().palette.primary.main }} className=" font-bold">
            Detalhes Financeiros
        </Divider>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2">
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker 
                    size = "small"
                    fullWidth
                    sx={{width: '100%'}}
                    label={'Data de Cadastro'} 
                    ampm={false} 
                    value={dayjs.tz(values?.CREATED_AT_GERENTE)}
                    
                    format="DD/MM/YYYY" 
                />
                <MobileDateTimePicker 
                    size = "small"
                    fullWidth
                    sx={{width: '100%'}}
                    label={'Início da Manutenção'} 
                    ampm={false} 
                    value={dayjs.tz(values?.START_MANUTENTION)}
                    format="DD/MM/YYYY" 
                />
            </LocalizationProvider>
        </div>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 md:grid-cols-2 mt-2"> 
            <TextField 
                size = "small"
                fullWidth
                value={Number(values?.MANUTENTION_VALUE  || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} 
                label="Valor de Manutenção" 
                 
            />
            <TextField 
                size = "small"
                fullWidth
                value={Number(values?.MANUTENTION_TOTAL  || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} 
                label="Total em Manutenção" 
            />
            <TextField 
                size = "small"
                fullWidth
                value={Number(values?.SALES_TOTAL  || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} 
                label="Total em Vendas" 
            />  
        </div>
        <TextField 
            label="Total Geral"
            size = "small"
            fullWidth        
            value={(Number(values?.SALES_TOTAL  || 0) + Number(values?.MANUTENTION_TOTAL  || 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}  
        />  
        <Divider sx={{ color: useTheme().palette.primary.main }} className=" font-bold">
            Historico de Vendas
        </Divider>
        <ClientesSalesTable client={props.client} />
    </div>

  )
}

export default ClienteFinanceDetails;