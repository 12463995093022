import SearchAndSelect from "../SearchAndSelect";
import { useQuery } from "react-query"
import { usersListFn } from "../../../services/user";

const UserSelect = ({ formik, name, label, setValue, value, error, helpText }) => {
    const { data, isLoading } = useQuery("users-list", () => usersListFn());
    const users = data?.map((i) => ({ label: i.name, value: i.id }));

    const handleSelectOption = async (id) => {
        const item = await data.find((i) => i?.id === id);
        setValue(item);
    }

    return <SearchAndSelect 
                name={name} 
                setValue={handleSelectOption} 
                value={value} 
                placeholder="Selecione uma opção" 
                label={label || "Atendente"} 
                options={users || []} 
                formik={formik} 
                loading={isLoading} 
                fullWidth
                className="mb-2"
                error={error}
                helpText={helpText}
            />
};
  

export default UserSelect;