import { DoDisturb } from "@mui/icons-material"
import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import CustomSelect from "../CustomSelect";
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useSearchParams } from "react-router-dom";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('pt-br');

const CustomPeriodSelect = ({ label = "Periodo", startDate, setStartDate, endDate, setEndDate, setDescription}) => {
  // const [option, setOption] = useState(0);
  const [labelPeriod, setLabelPeriod] = useState('');
  const [searchParams, setSearchParams] = useSearchParams([]);

  const options = [
    'Hoje',
    'Esta semana',
    'Este mês',
    'Este ano',
    'Últimos 30 dias',
    'Últimos 12 meses',
    'Todo o período',
    'Periodo personalizado'
  ];

  const option = useMemo(() => {
    return parseInt(searchParams.get('option_period')) || 0;
  }, [searchParams]);    


  const handleChangeOption = (value) => {
    setSearchParams({ option_period: value.toString() }, { replace: true })
  };


  const captionPeriod = useMemo(() => {
    switch(option){
      case 0: return 'Hoje - ' + dayjs(startDate).format('DD/MM/YYYY');
      case 1: return dayjs(startDate).format('DD/MM/YYYY') + ' até ' + dayjs(endDate).format('DD/MM/YYYY');
      case 2: return dayjs(startDate).format('MMMM') + ' de ' + dayjs(startDate).format('YYYY');
      case 3: return dayjs(startDate).format('YYYY');
      case 4: return dayjs(startDate).format('DD/MM/YYYY') + ' até ' + dayjs(endDate).format('DD/MM/YYYY');
      case 5: return dayjs(startDate).format('DD/MM/YYYY') + ' até ' + dayjs(endDate).format('DD/MM/YYYY');
      case 6: return 'Todo o período';
      case 7: return dayjs(startDate).format('DD/MM/YYYY') + ' até ' + dayjs(endDate).format('DD/MM/YYYY');
    }
  }, [startDate, endDate, option]);

  useEffect(() => {
    getPeriod();
    if(setDescription){
      setDescription(captionPeriod);
    }
  }, [captionPeriod])

  const setPeriodToday = () => {
    const date = dayjs().tz(dayjs.tz.guess()).startOf('day');
    setStartDate(date);
    setEndDate(date);
  };

  const setPeriodWeek = () => {
    // setStartDate(dayjs().day(0));
    // setEndDate(dayjs().day(6));
    setStartDate(dayjs().startOf('week'));
    setEndDate(dayjs().endOf('week'));
  };

  const setPeriodMonth = () => {
    setStartDate(dayjs().startOf('month'));
    setEndDate(dayjs().endOf('month'));
  };

  const setPeriodYear= () => {
    setStartDate(dayjs().startOf('year'));
    setEndDate(dayjs().endOf('year'));
  };
  const setPeriodAll= () => {
    setStartDate(dayjs('1900-01-01'));
    setEndDate(dayjs('2199-12-31'));
  };

  const setPeriodLast30Days = () => {
    const dateEnd = dayjs();
    const dateStart = dateEnd.subtract(30, 'day');
    setStartDate(dateStart);
    setEndDate(dateEnd);
  };

  const setPeriodLast12Month = () => {
    const dateEnd = dayjs();
    const dateStart = dateEnd.subtract(12, 'month');
    setStartDate(dateStart);
    setEndDate(dateEnd);
  };

  const getPeriod = () => {
    switch(parseInt(option)){
      case 0: setPeriodToday(); break;
      case 1: setPeriodWeek(); break; 
      case 2: setPeriodMonth(); break; 
      case 3: setPeriodYear(); break; 
      case 4: setPeriodLast30Days(); break; 
      case 5: setPeriodLast12Month(); break;  
      case 6: setPeriodAll(); break;  
    }
  }

  return (
    <>
      <CustomSelect
        value={option}
        onChange={(value) => handleChangeOption(value.target?.value)}
        label={label}
        placeholder="Selecione uma opção"
        displayEmpty
        renderValue={() => captionPeriod || "Selecione um período"}
        sx={{ minWidth: 250 }}
      >
        {
          options.map((op, index) => (<MenuItem value={index}>{op}</MenuItem>))
        }
      </CustomSelect>
      {
        option === 7  && (
          <div className="flex gap-3 justify-between">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePicker
                label="Inicio"
                name={'startDate'}
                disabled={false}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                format="DD/MM/YYYY"
                sx={{ "& .MuiInputBase-root": { height: '40px' } }} 
              />
              <DatePicker
                label="Fim"
                name={'endDate'}
                disabled={false}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                format="DD/MM/YYYY"
                sx={{ "& .MuiInputBase-root": { height: '40px' } }} 
              />
            </LocalizationProvider>
          </div>
        )
      }
    </>
  )
}

export default CustomPeriodSelect
