import React from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { ChartOptions as ChartJSOptions, ChartData } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  ChartDataLabels,
  
);
// Defina a interface dos props
interface ChartOptionsProps {
  typeChart: number; 
  options: any; 
  data: any; 
  optionChart: number;
}


export const ChartOptions: React.FC<ChartOptionsProps> = ({ typeChart, options, data, optionChart }) => {

  const getChartOption = () => {
    switch (optionChart) {
      case 1: return typeChart;
      case 2: return typeChart;
      case 3: return typeChart;
      case 4: return typeChart;
      case 5: return typeChart;
      case 6: return typeChart;
      case 7: return typeChart;
      case 8: return typeChart;
      case 9: return typeChart;
      case 10: return typeChart;
      case 11: return 3;
    }
  }

  if(!options || !data || !data?.labels || !data?.datasets) return <div>Dados não encontrados, verifique os filtros...</div>;
  switch (getChartOption()) {
    case 1:
      return <Bar options={options} data={data} />;
    case 2:
      return <Line options={options} data={data} />;
    case 3:
        return <Pie options={options} data={data} />;      
    default:
      return <div>Invalid chart type</div>;
  }
};
