import axiosInstance from "../../config/axio.config"; 
const users = '/user';

export const usersListFn = async (reqBody) => {
    try {
      const response = await axiosInstance.get(users+'/selectList', { params: reqBody })
      return response?.data 
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};

export const usersFn = async (reqBody) => {
    try {
      const response = await axiosInstance.get(users+'/all', { params: reqBody })
      return response?.data 
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};
  
export const usersGroupsFilterFn = async (reqBody) => {
    try {
      const response = await axiosInstance.get(users+'/groupsFilter', { params: reqBody })
      return response?.data 
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};
  