import { SxProps } from "@mui/material";
import { getWidthChart, getHeightChart } from "../utils/stylesUtils";
import useFiltersStore from '../store/filtersStore';
import { FilterState } from "../types/types";

export const chartContainerBodyH= (filters: FilterState, labelsCount: number): SxProps => {

    return {
        height: {xs: "calc(70vh - 80px)", sm: "calc(70vh - 80px)", md: "calc(70vh - 80px)", lg: "calc(75vh - 80px)", xl: "calc(82vh - 80px)"},
        width: getWidthChart(filters, labelsCount),
        overflowY: 'scroll',
        overflow: 'hidden',
        marginRight: '10px',
        // display: (filters?.orientation === 'x') ? '' : 'none'
    }
};    

export const chartContainerBodyV = (filters: FilterState, labelsCount: number): SxProps => {

    return {
        height: {xs: "calc(70vh - 80px)", sm: "calc(70vh - 80px)", md: "calc(80vh - 80px)", lg: "calc(75vh - 80px)", xl: "calc(82vh - 80px)"},
        width:  '97vw',
        overflowY: 'hidden',
        overflow: (getHeightChart(filters, labelsCount) == 'calc(82vh - 80px)' || (filters?.option === 4 || filters?.option === 6 || filters?.option === 7)) ? 'hidden' : 'scroll',
        marginRight: '0px',
        // display: (filters?.orientation === 'y') ? '' : 'none'
    };
}