import { useState } from "react";
import { Box, Button, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useQuery } from "react-query";
import { reasonsRequestsFn } from "../../../services/reasonsRequest";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ReasonManager from "./manager";

const Reasons = () => {

  const [filter, setFilter] = useState('');
  const { data : rows, isLoading, refetch } = useQuery(["reasons-requests-table"], () => reasonsRequestsFn({}));
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);

  const handleSearch = () => {
    
  };
  
  const handleEdit = (reason) => {
    setValues(reason);
    setOpen(true);
  }

  return (
    <Box sx={{ width: '100%', height: '100%'}}>
      <Paper elevation={3} sx={{ p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Busque por Nome Fantasia, Razão Social e CNPJ/CPF"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
        />
        <IconButton type="button" sx={{ p: '10px' }} title='Buscar' onClick={handleSearch}>
            <SearchIcon />
        </IconButton>
      </Paper >
      <Paper >
        <TableContainer sx={{ overflow: 'scroll', height: '80vh'}} >
          <Table stickyHeader  sx={{ width: '100%' }} >
            <TableHead>
              <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Nível</TableCell>    
                  <TableCell>Tipo</TableCell>     
                  <TableCell>Editar</TableCell>                                              
              </TableRow>
            </TableHead>
            <TableBody>
            {rows && rows.filter((row) => row.description.toLowerCase().includes(filter.toLowerCase())).map((row) => (
              <TableRow key={row.id} sx={{height: '15px'}} >
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left">{row.level}</TableCell>
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleEdit(row)}>
                      <EditOutlinedIcon />
                  </Button></TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ReasonManager values={values} open={open} setOpen={setOpen} refetch={refetch}  />
    </Box>    
  )
}

export default Reasons;
