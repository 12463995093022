import React, { useState, useEffect, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Autocomplete, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputBase, Popover, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

//hooks 
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../hooks/useMessages/useMessages';

import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import LoadingFull from '../shared/loadingFull';
import { useCryptoSessionStorage } from '../../hooks/useCryptoSessionStorage';

let url = process.env.REACT_APP_API + '/actualizations';
let urlAtualizacao = process.env.REACT_APP_API_ATUALIZACAO;
let token = '';

const connection = new HubConnectionBuilder()
    .withUrl(urlAtualizacao + "hub/atualizacoes", { 
        accessTokenFactory: () => token,
        withCredentials: false
    })
    .withAutomaticReconnect()
    .build();


const connectionActive = async () => {
  console.log(connection.state)
  return connection.state === HubConnectionState.Connected;
}

const startConnection = async () => {
  try {
      try {
        await connection.stop();
      } catch (error) { 
        console.log(error) 
      }
      await connection.start({ withCredentials: false });
      console.log('Conectado ao hub');
      return true;   
  } catch (error) {
      console.error('Erro ao iniciar a conexão:', error);
      return false; 
  }
};


export default function ClientsConnectedTable(props) {
  const { getData } = useCryptoSessionStorage();
  token = getData("userTokenAtt") || '';
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('');
  const { ShowMessage, msgConfirm } = useShowMessages();
  const { getDataDirectlyPost, loading} = useFetchData();
  const [clients, setClients] = useState([]);
  const [versions, setVersions ] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);

  const { data : listSystems } = useFetchData(url + '/getSystems');

  useEffect( () => {
    startConnection();
  }, [])

  const handleOpenVersions = (event, versoes) => {
    setVersions(versoes);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const IndexOfId = (connectionId) => {
    let i = clients?.map((item, index) => item.connectionId).indexOf(connectionId);
    console.log(i);
    return i;
  };
  
  const getClients = async (clients) => {
    await getDataDirectlyPost(url + '/getClientsConnected', {clients: clients},
    async (response) => {
      console.log('res', response)
      setClients(response.clients); 
      setClients(response.clients);
    }
    )
  }

  const listClients = useMemo(() => {
    const start = page * rowsPerPage;
    const last = start + rowsPerPage;

    const clientFilter = clients
    .filter((i) => i.client[0]?.id && i.nomeCompleto.toLowerCase().includes(filter.toLowerCase()) && (!filters?.id_sistema || i.versoes.filter((item) => item.id === filters?.id_sistema).length > 0 ))
    .sort((a, b) => {
      if (a.nomeCompleto < b.nomeCompleto) return -1;
      if (a.nomeCompleto > b.nomeCompleto) return 1;
      return 0;
    });

    setCount(clientFilter?.length);
    return clientFilter.slice(start, last);
  }, [clients, page, rowsPerPage, filter, filters]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handleChanged();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    props.handleChanged();
  };

  
  const handleSetFilter = (filter) => {
    setFilter(filter.target.value);
  }

  const atualizar = function (cliente, index) {
    connection.invoke("EnviarAtualizacao", cliente.connectionId);
  }

  function atualizarStatus(index, status, versoes, erro) {
    const clientsCopy = [...clients];
    clientsCopy[index] = {
      ...clientsCopy[index],
      status: status,
      erro: status !== "Erro" ? "" :  clientsCopy[index].userName = ':' + erro,
      versoes: versoes  ? versoes : clientsCopy[index].versoes,
    };
    console.log('c', clientsCopy);
    setClients(clientsCopy);
  }

  connection.on("ClienteIniciarAtualizacao", function (connectionId) {
    const index = IndexOfId(connectionId);
    if (index >= 0) {
      atualizarStatus(index, "Atualizando");
    }
  });

  connection.on("ClienteAtualizadoComSucesso", function (connectionId, versoes) {

    const index = IndexOfId(connectionId);

    if (index >= 0) {
        atualizarStatus(index, "Concluído", versoes);
    }

  });

  connection.on("ClienteSemAtualizacao", function (connectionId) {
    const index = IndexOfId(connectionId);
    if (index >= 0) {
        atualizarStatus(index, "Sem atualização");
    }
  });

  connection.on("ClienteErroAoAtualizar", function (connectionId, erro) {

    const index = IndexOfId(connectionId);

    if (index >= 0) {
        atualizarStatus(index, "Erro", false, erro);
    }
  });


  connection.on("ListarClientes", async (clientes) => {
    if(clientes) getClients(clientes);
  });


  const classeStatus = function (status) {
    switch (status) {
        case "Conectado": return "success";
        case "Desconectado": return "error";
        case "Concluído": return "success";
        case "Sem atualização": return "info";
        case "Atualizando": return "warning";
        case "Erro": return "error"
        default: return "";
    }
  };


  const countFilter = ()=>{

    let count = 0;

    if(filters.id_sistema){
      count = count + 1;
    }
 
    setFilters((prevValues) => (
      {...prevValues, 
        'count' : count}
    )); 
  }

  const handleCloseFilter = () => {
    setOpenFilter(false);
  }

  const handleOpenFilter = () => {
    setOpenFilter(true);
  }

  const applyFilter = () => {
    props.handleChanged();
    handleCloseFilter();
    countFilter();
  }

  const handleChangeSystem = (values) => {
    setFilters((prevValues) => (
      {
          ...prevValues, "sistema": (!values) ? null : values.sistema,
    }));  
    setFilters((prevValues) => (
      {
          ...prevValues, "id_sistema": (!values) ? '' : values.id,
    }));    
  }

  return (
    <Box sx={{ width: '100%', height: '100%'}}>
      <Paper elevation={3} sx={{ p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Busque por Nome Fantasia, Razão Social e CNPJ/CPF"
            value={filter}
            onChange={handleSetFilter}
        />
        <IconButton type="button" sx={{ p: '10px' }} title='Filtros' onClick={handleOpenFilter}>
          <Badge color="primary" variant="standard" badgeContent={filters?.count}>
            <FilterListIcon />
          </Badge>        
        </IconButton> 
        <IconButton type="button" sx={{ p: '10px' }} title='Buscar'>
            <SearchIcon />
        </IconButton>
      </Paper >
      <Paper >
        <TableContainer sx={{ overflow: 'scroll', height: '70vh'}} >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography sx={{ p: 2 }}>Versões Atuais</Typography>
          <Table stickyHeader  sx={{ width: '100%' }} >
            <TableHead>
              <TableRow>
                  <TableCell>Sistema</TableCell>
                  <TableCell>Versão</TableCell>                                            
              </TableRow>
            </TableHead>
            <TableBody>
              {versions && versions.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{row.sistema}</TableCell>
                      <TableCell align="left">{row.versaoAtual}</TableCell>
                    </TableRow>
              ))}
            </TableBody>
          </Table>
        </Popover>
          <Table stickyHeader  sx={{ width: '100%' }} >
            <TableHead>
              <TableRow>
                  <TableCell>Nome Fantasia</TableCell>
                  <TableCell>Usuário</TableCell>
                  <TableCell>Senha</TableCell> 
                  <TableCell align="center">Conexão</TableCell> 
                  <TableCell align="center">Ações</TableCell>                                              
              </TableRow>
            </TableHead>
            <TableBody>
            <LoadingFull open={loading} /> 
              {listClients &&
                listClients
                .map((row, index) => {
                  if (row.client && row.client[0]?.id)
                    return (
                      <TableRow key={row.client[0]?.id} sx={{ height: "25px" }}>
                        <TableCell component="th" scope="row">
                          {row.nomeCompleto}
                        </TableCell>
                        <TableCell align="left">{row.userName}</TableCell>
                        <TableCell align="left">{row.client[0]?.cpf_cnpj}</TableCell>
                        <TableCell align="center">
                          <Button 
                            onClick={(e) => {handleOpenVersions(e, row.versoes)}} 
                            variant={row.status !== 'Concluído' ? "outlined" : "contained"}  
                            color={classeStatus(row.status)} 
                            sx={{minWidth: 170}}
                          >
                            {row.status}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" onClick={() => atualizar(row, index)}>
                            Atualizar
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Registros por página'
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(count && count) ? count : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog open={openFilter} onClose={handleCloseFilter}> 
        <DialogTitle>Filtrar Módulos / Sistemas</DialogTitle>
        <DialogContent sx={{minHeight: 300}}>
        <Grid container spacing={1} columns={ 12 } sx={{float: "center"}} >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
            <Autocomplete
          
              fullWidth
              options={listSystems}
              sx={{mt: 1, width: 400}}
              getOptionLabel={ (option) => option['sistema'] || option }    
              value={filters?.sistema}
              onChange={(event, values) => handleChangeSystem(values)}
              renderInput={(params) => 
              <TextField
                name="sistema"
                {...params} label="Módulo / Sistemas" 
              />}
              />          
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={applyFilter}>Aplicar</Button>
        </DialogActions>        
      </Dialog>
    </Box>
  );
}