import './loading.css'
import { Box } from "@mui/material";

function LoadingFull(props) {
  return (
    <Box   sx={{display: (props.open) ? 'flex' : 'none',}}>
        <Box className="sweet-loading">
            <div class="spinner1 is-animating">
            </div>
            <div class="spinner2 is-animating">
            </div>                
        </Box>
    </Box>
  );
}

export default LoadingFull;