
export const levelsSupport = [
    { value: 1, label: 'Nível 1' },
    { value: 2, label: 'Nível 2' },
    { value: 3, label: 'Nível 3' },
    { value: 4, label: 'Nível 4' },
    { value: 5, label: 'Nível 5' },
];


export const getHoursByMinutes = (duration) =>{ 

    if(!duration) return 0;
    if(duration < 60) return `${duration}min`;
    
    const hours = Math.floor(duration / 60); 
    const minutes = duration % 60; 
    return `${hours}h ${minutes}min`; 
}