//React - React Dom
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

//Mui 
import LogoutIcon from '@mui/icons-material/Logout';
import { Collapse, Tooltip } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LanIcon from '@mui/icons-material/Lan';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionIcon from '@mui/icons-material/Description';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import LanguageIcon from '@mui/icons-material/Language';
import CategoryIcon from '@mui/icons-material/Category';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import HelpIcon from '@mui/icons-material/Help';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import KeyIcon from '@mui/icons-material/Key';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import WidgetsIcon from '@mui/icons-material/Widgets';

//Hooks
import { useAuthUser } from '../../../hooks/useAuthUser/useAuthUser';
import { useCryptoData } from '../../../hooks/useCryptoData/useCryptoData';

//Componentes 
import UsersPage from '../administrative/usersPage/userPage';
import UserGroupsPage from '../administrative/usersPage/userGroups/userGroupsPage';
import Manuals from '../../../components/manuais/ListagemManuais/manuals';
import PublicationsPage from '../../../components/helpdesk/publications/publicationsPage/PublicationsPage';
import PageImages from '../../../components/helpdesk/images/pageImages/PageImages'
import PageCategories from '../../../components/helpdesk/categories/pageCategories/pageCategories';
import RequestsPanelPage from '../requestsPages/panelPage/panelPage';
import ClientsPage from '../administrative/clientsPage/clientsPage';
import RequestsPage from '../requestsPages/requestsPage/requestsPage';
import RequestsChartsPage from '../../../features/charts/pages/ChartsPage.tsx';
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import dayjs from 'dayjs';
import { useShowMessages } from '../../../hooks/useMessages/useMessages';
import ClientsActualizations from '../administrative/actualizations/clientsListActualizations';
import SystemsTable from '../../../components/actualizations/systemsTable';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FilesPage from '../administrative/files/filesPage';
import FilesPageCategories from '../administrative/files/pageCategories';
import UsersActualizationsPage from '../administrative/actualizations/usersActualizationsPage';
import CriarPub from '../../../components/helpdesk/publications/publication/Publication';
import { useCryptoSessionStorage } from '../../../hooks/useCryptoSessionStorage';
import Reasons from '../../../components/requests/reasons';
import ClientsNotRequests from '../../../components/administrative/clients/clientsNotRequests';


const drawerWidth = 240;
const urlDailyPassword = process.env.REACT_APP_API+ "/user/dailyPassword";


const darkTheme = () => {
  let theme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#3b3b3b',
        paper: '#3b3b3b',
      }
    },
    typography: {
  
      // htmlFontSize: {xs: 20, sm: 20, md: 25, },
    },

  });

  // theme.typography.h7 = {
  //   fontSize: '0.7rem',
  //   [theme.breakpoints.up('xl')]: {
  //     fontSize: '',
  //   },
  // };

  return theme;
}


const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
  
    htmlFontSize: {xs: 18, sm: 18, md: 18, lg: 15, xl: 15},
    h7: {
      fontSize: '0.7rem',
    }
  },


});


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    overflow: 'hidden',
    padding: 0, 
    height: '100vh', 
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));





export default function MainPage() {
  const location = useLocation();
  const { state } = location;
  const pageTitle = state?.pageTitle || 'Sirrus Atendimento'; 
  const { getData } = useCryptoSessionStorage();
  const user = getData("user") || {};
  const theme = useTheme();
  const { componentUrl } = useParams();
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState(<RequestsPanelPage/>);
  const { signOut } = useAuthUser();
  const navigate = useNavigate();
  const [mode, setMode] = useState((localStorage.getItem('theme') === 'dark') ? darkTheme : lightTheme); //
  const { getDataDirectly } = useFetchData();
  
  const { ShowMessage } = useShowMessages();

  //State dos menus expansíveis 
  const [expandedMenu, setExpandedMenu] = useState('');
  const [expandedMenuRequests, setExpandedMenuRequests] = useState(false); 
  const [expandedMenuAtt, setExpandedMenuAtt] = useState(false); 
  const [expandedMenuFiles, setExpandedMenuFiles] = useState(false); 
  const [expandedMenuHelpDesk, setExpandedMenuHelpDesk] = useState(false); 
  const [expandedMenuAdm, setExpandedMenuAdm] = useState(false); 

  const handleExpandRequests = () => {
    setExpandedMenuRequests(!expandedMenuRequests);
  };
   
  const handleExpandAtt = () => {
    setExpandedMenuAtt(!expandedMenuAtt);
  };  

  const handleExpandFiles = () => {
    setExpandedMenuFiles(!expandedMenuFiles);
  };  

  const handleExpandHelpDesk = () => {
    setExpandedMenuHelpDesk(!expandedMenuHelpDesk);
  };   

  const handleExpandAdm = () => {
    setExpandedMenuAdm(!expandedMenuAdm);
  };   

  const handleExpandMenu = (menu) => {
    expandedMenu === menu ? setExpandedMenu('') : setExpandedMenu(menu);
  }

  const searchComponent = (component) => {
    switch(component){
      case('atendimentos'): if(user?.requests_list === 'Y'){
        setComponent(<RequestsPage/>);
      }; break;
      case('painel'): if(user?.requests_panel === 'Y') setComponent(<RequestsPanelPage/>); break;
      case('reasons'): if(user?.requests_fr === 'Y') setComponent(<Reasons/>); break;
      case('charts'): if(user?.requests_charts === 'Y') setComponent(<RequestsChartsPage />); break;
      case('actualizations_clients'):{} if(user?.actualizations_panel === 'Y') setComponent(<ClientsActualizations />); break;
      case('actualizations_systems'): if(user?.actualizations_files === 'Y') setComponent(<SystemsTable />); break;
      case('actualizations_users'): if(user?.actualizations_users === 'Y') setComponent(<UsersActualizationsPage />); break;
      case('files'): if(user?.files_panel === 'Y') setComponent(<FilesPage />); break;
      case('files_categories'): if(user?.files_categories === 'Y') setComponent(<FilesPageCategories />); break;
      case('manuais'): setComponent(<Manuals admin={true} />); break;
      case('h'): {if(user?.helpdesk_pubs === 'Y')setComponent(<PublicationsPage setComponent={setComponent} admin={true} />); break;} 
      case('publication'): setComponent(<CriarPub setComponent={setComponent} />); break;
      case('imagens'): if(user?.helpdesk_images === 'Y')setComponent(<PageImages setComponent={setComponent} />); break;
      case('categorias'): if(user?.helpdesk_categories === 'Y')setComponent(<PageCategories setComponent={setComponent} />); break;
      case('usuarios'): if(user?.administration_users === 'Y')setComponent(<UsersPage />); break;
      case('usuariosgrupos'): if(user?.administration_users_groups === 'Y')setComponent(<UserGroupsPage />); break;
      case('clientes'): if(user?.administration_clients === 'Y')setComponent(<ClientsPage />); break;
    }
  }

  useEffect(() => {searchComponent(componentUrl)}, [componentUrl]);
 
  const handleSignout = () => {
    signOut();
    navigate("/admin");  
    window.location.reload(false);
  }

  const handleComponent = (component, url, title) => {
    setComponent(component);
    setExpandedMenuHelpDesk(false);
    setExpandedMenuAdm(false);
    navigate(`/admin/${url}`, { state: { pageTitle: title } });  
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setExpandedMenuHelpDesk(false)
  };


  const handleMode = (theme) => {
    if(theme === 'dark') {
      setMode(darkTheme)
      localStorage.setItem('theme', 'dark');
    } else {
      setMode(lightTheme)   
      localStorage.setItem('theme', 'light');
    }
    
  }

  const getDailyPassword = async () =>{
    const now = dayjs().utc(true).format('DD/MM/YYYY');
    
    getDataDirectly(urlDailyPassword, {user: user.id, date: now}, ((response)=> {
      navigator.clipboard.writeText(response.password);
      ShowMessage('success',  'Copiado para área de transferência'); 
    })
    )
  }


  const buttonsRequests = [
    { 
      id: 1,
      title: 'Painel de Atendimento',
      component: (<RequestsPanelPage/>),
      icon: (<LanIcon />),
      to: 'painel',
      display: (user?.requests_panel === 'Y') ? '' : 'none',
    },
    { 
      id: 2,
      title: 'Atendimentos',
      component: (<RequestsPage />),
      icon: (<PlagiarismIcon />),
      to: 'atendimentos',
      display: (user?.requests_list === 'Y') ? '' : 'none',
    },
    { 
      id: 3,
      title: 'Tipos de Atendimento',
      component: (<Reasons />),
      icon: (<BarChartIcon />),
      to: 'reasons',
      display: (user?.requests_fr === 'Y') ? '' : 'none',
    },
    { 
      id: 4,
      title: 'Gráficos',
      component: (<RequestsChartsPage />),
      icon: (<BarChartIcon />),
      to: 'charts',
      display: (user?.requests_charts === 'Y') ? '' : 'none',
    },
  ];

  const buttonsHelpDesk = [
    { 
      id: 1,
      title: 'Publicações',
      component: (<PublicationsPage admin={true} />),
      icon: (<LanguageIcon />),
      to: 'h',
      display: (user?.helpdesk_pubs === 'Y') ? '' : 'none',
    },
    { 
      id: 2,
      title: 'Imagens',
      component: (<PageImages />),
      icon: (<PhotoLibraryIcon />),
      to: 'imagens',
      display: (user?.helpdesk_images === 'Y') ? '' : 'none',
    },
    { 
      id: 3,
      title: 'Categorias',
      component: (<PageCategories />),
      icon: (<CategoryIcon />),
      to: 'categorias',
      display: (user?.helpdesk_categories === 'Y') ? '' : 'none',
    },
  ]
  
  const buttonsAtt = [
    { 
      id: 1,
      title: 'Painel',
      component: (<ClientsActualizations />),
      icon: (<GroupIcon />),
      to: 'actualizations_clients',
      display: (user?.actualizations_panel === 'Y') ? '' : 'none',
    },
    { 
      id: 2,
      title: 'Versões Sistemas',
      component: (<SystemsTable />),
      icon: (<WidgetsIcon />),
      to: 'actualizations_systems',
      display: (user?.actualizations_files === 'Y') ? '' : 'none',
    },  
    { 
      id: 2,
      title: 'Usuários',
      component: (<UsersActualizationsPage/>),
      icon: (<GroupIcon />),
      to: 'actualizations_users',
      display: (user?.actualizations_users === 'Y') ? '' : 'none',
    },  
  ];

  const buttonsFiles = [
    { 
      id: 1,
      title: 'Arquivos',
      component: (<FilesPage />),
      icon: (<GroupIcon />),
      to: 'files',
      display: (user?.files_panel === 'Y') ? '' : 'none',
    },
    { 
      id: 2,
      title: 'Categorias',
      component: (<FilesPageCategories />),
      icon: (<WidgetsIcon />),
      to: 'files_categories',
      display: (user?.files_categories === 'Y') ? '' : 'none',
    },    
  ];

  const buttonsAdm = [
    { 
      id: 1,
      title: 'Usuários',
      component: (<UsersPage />),
      icon: (<GroupIcon />),
      to: 'usuarios',
      display: (user?.administration_users === 'Y') ? '' : 'none',
    },
    { 
      id: 1,
      title: 'Grupos de Usuários',
      component: (<UserGroupsPage />),
      icon: (<GroupIcon />),
      to: 'usuariosgrupos',
      display: (user?.administration_users_groups === 'Y') ? '' : 'none',
    },    
    { 
      id: 2,
      title: 'Clientes',
      component: (<ClientsPage />),
      icon: (<GroupIcon />),
      to: 'clientes',
      display: (user?.administration_clients === 'Y') ? '' : 'none',
    },
  ];

  const buttonsReports = [
    { 
      id: 1,
      title: 'Cliente que não solicitaram atendimentos',
      component: (<ClientsNotRequests />),
      icon: (<DescriptionIcon />),
      to: 'clients_not_requests',
      display: (user?.files_panel === 'Y') ? '' : 'none',
    },  
  ];

  const menus = [
    {
      id: 7,
      title: 'Relatórios',
      name: 'reports',
      icon: (<DescriptionIcon />),
      buttons: buttonsReports
    }
  ]

  return (
    <Box sx={{ display: 'flex'}}>
    <ThemeProvider theme={mode}>
      <CssBaseline />
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 1, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" >
            {pageTitle}
          </Typography>  
  
          <Box sx={{ position: 'fixed', right: '10px', display: "flex", 
              ...(open && { display: {xs: 'none', sm: "flex"} })
            }}>  
            <Box sx={{ p: 0,  mr: {xs: 1, sm: 2 }}}>
              <Tooltip title='Senha Diária' color='white'>
                <IconButton onClick={getDailyPassword} sx={{color: 'white'}} >
                  <KeyIcon/>
                </IconButton>
              </Tooltip>                
            </Box> 
            <Box sx={{ p: 0,  mr: {xs: 1, sm: 2 }}}>    
              {            
              (mode === lightTheme) ? 
              <Tooltip title='Apagar as luzes' color='white' display={"flex"}>
                <IconButton onClick={() => handleMode('dark')} sx={{  color: 'white' }}>
                  <Brightness4Icon />
                </IconButton>
              </Tooltip>  
              :
              <Tooltip title='Acender as luzes' color='white' >
              <IconButton onClick={() => handleMode('light')} sx={{color: 'white' }}>
                <Brightness7Icon />
              </IconButton>
              </Tooltip>    
              }
            </Box>
            <Box sx={{ p: 0 }}>  
              <Tooltip title='Sair' color='white'>
                <IconButton onClick={handleSignout} sx={{color: 'white' }}>
                  <LogoutIcon/>
                </IconButton>
              </Tooltip>
            </Box> 
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            height: '100vh'
          },
          
        }}
        variant="persistent"
        anchor="left"
        open={open}
        
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton onClick={handleExpandRequests} sx={{display: (user?.requests === 'Y') ? '' : 'none',}}>
            <ListItemIcon>
              <SupportRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Atendimentos" />
            {expandedMenuRequests ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
            <Collapse in={expandedMenuRequests} timeout="auto" unmountOnExit sx={{ textAlign: "left", pl: 5}}>
              <List component="div" disablePadding>
              {buttonsRequests.map((item) => {
                    return (
                      <ListItemButton 
                        key={item.id} 
                        onClick={() => handleComponent(item.component , item.to, item.title)} 
                        sx={{display: item.display}}
                      >
                        <ListItemIcon>
                          {item.icon} 
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    )
                  })}
              </List>
            </Collapse>
            <Divider />
            <ListItemButton onClick={handleExpandAtt} sx={{display: (user?.actualizations === 'Y') ? '' : 'none',}}>
              <ListItemIcon>
                <BrowserUpdatedIcon />
              </ListItemIcon>
              <ListItemText primary="Atualizações" />
              {expandedMenuAtt ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expandedMenuAtt} timeout="auto" unmountOnExit sx={{ textAlign: "left", pl: 5}}>
              <List component="div" disablePadding>
              {buttonsAtt.map((item) => {
                    return (
                      <ListItemButton 
                        key={item.id} 
                        onClick={() => handleComponent(item.component , item.to, item.title)} 
                        sx={{display: item.display}}
                      >
                        <ListItemIcon>
                          {item.icon} 
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    )
              })}
              </List>
            </Collapse>
            <Divider />  
            <ListItemButton onClick={handleExpandFiles} sx={{display: (user?.files === 'Y') ? '' : 'none',}}>
              <ListItemIcon>
                <DriveFolderUploadIcon />
              </ListItemIcon>
              <ListItemText primary="Servidor de Arquivos" />
              {expandedMenuFiles ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expandedMenuFiles} timeout="auto" unmountOnExit sx={{ textAlign: "left", pl: 5}}>
              <List component="div" disablePadding>
              {buttonsFiles.map((item) => {
                    return (
                      <ListItemButton 
                        key={item.id} 
                        onClick={() => handleComponent(item.component , item.to, item.title)} 
                        sx={{display: item.display}}
                      >
                        <ListItemIcon>
                          {item.icon} 
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    )
              })}
              </List>
            </Collapse>           
            <ListItem  
              disablePadding
              onClick={() => handleComponent(<Manuals admin={true}/>, 'manuais', 'Manuais')}>
              <ListItemButton>
                <ListItemIcon>
                  <QuizIcon /> 
                </ListItemIcon>
                <ListItemText primary={"Manuais"} />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItemButton onClick={handleExpandHelpDesk} sx={{display: (user?.helpdesk === 'Y') ? '' : 'none',}}>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Base de Conhecimento" />
              {expandedMenuHelpDesk ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expandedMenuHelpDesk} timeout="auto" unmountOnExit sx={{ textAlign: "left", pl: 5}}>
              <List component="div" disablePadding>
              {buttonsHelpDesk.map((item) => {
                    return (
                      <ListItemButton 
                        key={item.id} 
                        onClick={() => handleComponent(item.component , item.to, item.title)}
                        sx={{display: item.display}}
                      >
                        <ListItemIcon>
                          {item.icon} 
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    )
              })}
              </List>
            </Collapse>
            <Divider /> 
            {/* Menus Administrativos  */}
            <ListItemButton onClick={handleExpandAdm} sx={{display: (user?.administration === 'Y') ? '' : 'none',}}>
              <ListItemIcon>
                <DisplaySettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Administração" />
              {expandedMenuAdm ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expandedMenuAdm} timeout="auto" unmountOnExit sx={{ textAlign: "left", pl: 5}}>
              <List component="div" disablePadding>
              {buttonsAdm.map((item) => {
                    return (
                      <ListItemButton 
                        key={item.id} 
                        onClick={() => handleComponent(item.component , item.to, item.title)} 
                        sx={{display: item.display}}
                      >
                        <ListItemIcon>
                          {item.icon} 
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    )
                  })}
              </List>
            </Collapse>    
            {/* {
              menus.map((item) => (
                <>
                  <ListItemButton onClick={() => handleExpandMenu(item.name)} sx={{display: item.display}}>
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                    {expandedMenu === item.name ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={expandedMenu === item.name} timeout="auto" unmountOnExit sx={{ textAlign: "left", pl: 5}}>
                    <List component="div" disablePadding>
                      {item.buttons.map((button) => (                     
                            <ListItemButton 
                              key={button.id} 
                              onClick={() => handleComponent(button.component , button.to, button.title)} 
                              sx={{display: button.display}}
                            >
                              <ListItemIcon>
                                {button.icon} 
                              </ListItemIcon>
                              <ListItemText primary={button.title} />
                            </ListItemButton>  
                      ))
                      }
                    </List>
                  </Collapse>
              </>
              ))
            } */}
        </List>
        <div className='div-by' >
          <p>&copy;Sirrus Sistemas {new Date().getFullYear()}</p>
        </div>  
      </Drawer>
      <Main container open={open} onClick={handleDrawerClose}>
        <DrawerHeader />
        {component}
      </Main>
      </ThemeProvider>
    </Box>
  );
}
