import { get } from "react-hook-form";
import { getLegendPosition } from "../utils/stylesUtils";

const getDataLabels = (option: number, value: any) => {
    if(!value) return '';

    switch (option) {
        case 3: return value;
        case 11: return value['Total']; 
            if(value['Info'] == 'Manutenção'){
                return  value['Total'].toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
            } else {
               return value['Total']; 
            }
        default: return value;
    }
}

const scalesDefault = {
    yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
    }],
    x: {
        ticks: {
            font: {
                family: "Arial", 
                size: 14,        
                weight: "bold", 
            },
            color: localStorage.getItem('theme') === 'dark' ? 'white' : 'black'
        }
    }
}

const scalesDouble = {
    yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
    }, {
        id: 'B',
        type: 'linear',
        position: 'right',
        ticks: {
        max: 1,
        min: 0
        }
    }]
}

export const getChartOptionsHorizontal = (option: number, orientation: string, animations: boolean, handleClickRequest: Function) => {

    return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 5,
        scales: option === 8 || option === 10 || option === 11 ? scalesDefault : scalesDouble,
        indexAxis: 'x',
        parsing: {
            xAxisKey: 'Data',
            yAxisKey: 'Total',
        },
        plugins: {
            legend: {
                value: getLegendPosition(option, orientation),
                display: option != 3
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value: any) => {
                    return getDataLabels(option, value);
                },
                color: 'black',
                font: {
                    size: 14,
                },
            },
        },
        interaction: {
            mode: 'x',
        },
        onClick: (e: Event, i: Array<any>) => {
            if (i.length > 0 && (option === 3 || option === 4 || option === 5)) {
                handleClickRequest(i);
            }
        },
        animations: animations ? {
            tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: true,
            },
        } : {},
    };
};

export const optionsVertical = (option: number, orientation: string, animations: boolean, handleClickRequest: Function) => {

    return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        indexAxis: 'y',
        plugins: {
            legend: getLegendPosition(option, orientation),
        },
        interaction: {
            mode:  'y'
        },
        onClick: (e: Event, i: Array<any>) => {
            if( i.length > 0 && (option === 3 || option === 4 || option === 5 || option === 6 )){
                handleClickRequest(i)
            }
        },
        animations: (animations) ? {
            tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: true
            }
        } : {},
    };
};        
