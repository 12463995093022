import { Box, Button, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material';
import CustomSelect from '../../../shared/CustomSelect';
import { levelsSupport } from '../../../../helpers';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { createReasonRequestFn, updateReasonRequestFn } from '../../../../services/reasonsRequest';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

const ReasonManager = (props) => {

    const { mutate: updateReasonRequest, isUpdating} = useMutation(updateReasonRequestFn, {
        onSuccess: ({ data }) => {
          toast.success(data.message)
          props.refetch()
          props.setOpen(false)
        },
        onError: ({ response }) => {
          toast.error(response.data.message)
        },
      })
    
      const { mutate: createReasonRequest, isAdding } = useMutation(createReasonRequestFn, {
        onSuccess: ({ data }) => {
          toast.success(data.message)
          props.refetch();
          formik.resetForm();
          props.setOpen(false);
        },
        onError: ({ response }) => {
          toast.error(response.data.message)
        },
      })

    const validationSchema = Yup.object({
        description: Yup.string()
            .required("Informe a descrição")
            .typeError("Descrição inválida"),
        
        level: Yup.number()
            .required("Informe o nível de suporte") 
            .typeError("Nível Inválido"),	
        
    });

    const initialValues = {
        id: props.values.id || 0,
        description: props.values.description || '',
        type: props.values.type,
        level: props.values.level || 1,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const reqBody = new FormData();
            Object.keys(values).forEach((key) => {
            reqBody.append(key, values[key]);
            });
            (values?.id > 0) ? updateReasonRequest(values) : createReasonRequest(values)
        },
    });

    const handleClose = () => {
        props.setOpen(false)
    }

    const segments = [{
        value: 'Automação',
        label: 'Automação'
    }, {
        value: 'Ponto',
        label: 'Ponto'
    }]

  return (
    <Dialog open={props.open} className='dialog-new-motivo' >
        <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Cadastro de Solicitações</DialogTitle>
        <Box sx={{m: 1}}>
            <TextField
                className='label-description'
                autoFocus
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                defaultValue=" "
                label="Descrição da Solicitação"
                type="text"
                fullWidth
                variant="outlined"
                sx={{marginBottom: 2}}
            />
            <CustomSelect 
                id="type" 
                label="Seguimento" 
                formik={formik} 
                placeholder="Escolha o seguimento" 
                fullWidth  
                options={segments}
                className='mb-2'
            />
            <CustomSelect 
                id="level" 
                label="Nível de Suporte" 
                formik={formik} 
                placeholder="Escolha o nível" 
                fullWidth  
                options={levelsSupport}
            />
        </Box>         
        <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="submit" disabled={isAdding || isUpdating}>Salvar</Button>
        </DialogActions>
        </form>  
    </Dialog>
  )
}

export default ReasonManager;