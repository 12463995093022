import './loading.css'
import { Box } from "@mui/material";

function Loading(props) {
  return (
    <Box sx={{display: (props.open) ? 'flex' : 'none',}}>
        <Box className="sweet-loading-small">
            <div class="spinner1 is-animating">
            </div>
            <div class="spinner2 is-animating">
            </div>                
        </Box>
    </Box>
  );
}

export default Loading;