import React, {useState, useMemo, useEffect} from 'react';
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import dayjs from 'dayjs';
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { DatePicker, LocalizationProvider, ptBR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import './ChartsPage.css';
import RequestsTableModalCharts from '../../../components/requests/requestsTable/requestsTableModalCharts';
import RequestAdmin from '../../../components/requests/requestsModais/requestAdmin/requestAdmin';
import LoadingFull  from '../../../components/shared/loadingFull';

//Types 
import { dataType } from '../types/types';
//Utils
import { months } from '../utils/dateUtils';

//styles
import { chartContainerBodyH, chartContainerBodyV } from '../styles/styles';
import { getHeightChart } from '../utils/stylesUtils';
import { ChartOptions } from '../components/ChartOptions';
import { getChartOptionsHorizontal, optionsVertical } from '../config/chartOptions';
import useFiltersStore from '../store/filtersStore';
import UserSelect from '../../../components/shared/UserSelect';
import Client from '../../../components/administrative/clients/client/client';
import { clientFn } from '../../../services/clients';
import { set } from 'react-hook-form';
import UserGroupSelect from '../../../components/shared/GroupUserSelect';
import { useCryptoSessionStorage } from '../../../hooks/useCryptoSessionStorage';
import ClientsNotRequests from '../../../components/administrative/clients/clientsNotRequests';

const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';
let url = process.env.REACT_APP_API+ "/charts";
let urlUsers = process.env.REACT_APP_API+ "/user/all";

const RequestsChartsPage = () => {
    const { getData } = useCryptoSessionStorage();
    const user = getData("user") || {};    
    const [data, setData] = useState<dataType>({labels: [], datasets: []});
    const [totalRequests, setTotalRequests] = useState(0);
    const [filtersValues, setFiltersValues] = useState<any[]>([]); //Acessar os atendimentos clicando no gráfico
    const [animations, setAnimations] = useState(false);
    const [changed, setChanged] = useState(false);
    const [openConfig, setOpenConfig] = useState(false);
    const [requestsList, setRequestsList] = useState<any[]>([]);
    const [labelsCount, setLabelCount] = useState(0);
    const [requestsClick, setRequestsClick] = useState({});
    const [openRequest, setOpenRequest] = useState(false);
    const [values, setValues] = useState({});
    const [requestsClickOpen, setRequestsClickOpen] = useState(false);
    const [client, setClient] = useState({});
    const [openClient, setOpenClient] = useState(false);
    const filters = useFiltersStore(state => state);

    const optionsReports = [
        { value: 1, label: 'Atendimentos Geral por tipo - Mês/Ano' },
        { value: 2, label: 'Atendimentos Geral - Período' },
        { value: 3, label: 'Atendimentos por Usuário - Dia a Dia' },
        { value: 4, label: 'Total de Atendimentos - Período - Ord. Nome' },
        { value: 5, label: 'Total de Atendimentos - Período - Ord. Quantidade' },
    ];
    const optionsReportsAdm = [
        { value: 6, label: 'Clientes que solicitaram mais atendimentos- Geral' },
        { value: 7, label: 'Tipos de Atendimentos mais solicitados' },
        { value: 8, label: 'Total de Atendimentos por Cliente x Valor de Manutenção' },
        { value: 9, label: 'Total de Atendimentos por Sistema x Valor de Manutenção' },
        { value: 10, label: 'Clientes que não solicitaram atendimentos por sistema' },
        { value: 11, label: 'Clientes que Solicitaram x Não Solicitaram atendimentos' },
        { value: 12, label: 'Clientes que não solicitaram atendimentos - Lista' },
    ];

    const segments = [
       'Todos',
       'Automação',
       'Ponto',
       'Assistência',
       'Gestão',
       'Interno',
    ];

    const {  getDataDirectly, loading } = useFetchData();
    
    const handleChanged = () => {
        setChanged(!changed);
    }

    const handleCloseConfig = () => {
        setOpenConfig(false);
      }
    
      const handleOpenFilter = () => {
        setOpenConfig(true);
    }

    const handleChangeFilters = (value: any) => {
        useFiltersStore.setState((prevState) => ({
          ...prevState,
          [value.target.name]: value.target.value,
        }));
      
        if (value.target.name === 'option') {
            if(value.target.value === 4 || value.target.value === 5 || value.target.value === 6 || value.target.value === 7){
                handleChangeOrientation('y');
              } else {
                handleChangeOrientation('x');
            }
        } 
    };
      
    const handleChangeOrientation = (value: 'x' | 'y') => {
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            orientation: value,
        }));
    };


    const handleChangeFiltersDate = (field: string, value: any) => {
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            [field]: dayjs(value),
        }));
    };

    const handleChangeUser = (values: any) => {
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            ['user']: (!values) ? null : values.id,
        })); 
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            ['userName']: (!values) ? null : values.name,
        }));          
    };

    const handleChangeUserGroup = (values: any) => {
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            ['userGroup']: (!values) ? null : values.id,
        })); 
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            ['userGroupName']: (!values) ? null : values.description,
        }));          
    }

    const handleOpenRequests = () => {
        setRequestsClickOpen(true);
    };

    const handleOpenClient = async (id: number) => {
        const client = await clientFn({id});
        if(client){
            setClient(client);
            setOpenClient(true);
        }
    };

    const handleClickRequest = async (i: any) => {

        console.log(i);

        const date: any = filtersValues[i[0].index];
        if(filters?.option === 6 ){
           return handleOpenClient(date);
        }

        const user = data.datasets[i[0].datasetIndex].label;
        const userLabel = data.labels[i[0].index];
        const setRequests = () => {
            return new Promise((resolve, reject) => {
                let requests = {};
                switch (filters?.option) {
                    case 3: {
                       requests = requestsList.filter((r) => {
                            return r?.nameUser === user && r?.conclusion_day === dayjs(date.day).format('DD/MM/YYYY');
                       });
                       break;
                    };
                    case 4: {
                        requests = requestsList.filter((r) => {
                             return  r?.nameUser === userLabel;
                        });
                        break;
                     };      
                    default:
                        break;
                }

                resolve(setRequestsClick(requests));
            })
        };

        await setRequests();
        handleOpenRequests();
    }

    const updateFilter = async() => {
        setData({
            labels: [],
            datasets: []
        });
        setTotalRequests(0);

        if(filters?.option === 12){
            handleCloseConfig();
            handleChanged();
            return;
        }
        await getDataDirectly(url, {
            chart: true,
            year: filters?.year,
            month: filters?.month,
            user: (filters?.option === 4 || filters?.option === 5) ? 0 : filters?.user,
            option: filters?.option,
            FilterDate: filters?.option > 1,
            InitialDate: dayjs(filters?.InitialDate).utc(true).format('YYYY-MM-DD'),
            FinalDate: dayjs(filters?.FinalDate).utc(true).format('YYYY-MM-DD'),
            order: (filters?.option === 5 || filters?.option === 3 || filters?.option === 7) ? 'total' : 'name', 
            orderChart: filters?.option === 3 ?  filters?.orderChart : 'desc',
            userGroup: filters?.userGroup,
            limit: filters?.limit || 10,
            segment: filters?.segment
        },
        async (response: any) => {
            setData(response?.chartData);
            setTotalRequests(response?.totalRequests);
            setFiltersValues(response?.filters);
            handleCloseConfig();
            handleChanged();
            // setRequestsList(response);
            // await getDataChart(response).then(() => {
            //     handleCloseConfig();
            //     handleChanged();
            // });   
        }
        )
    }

    useEffect(() => {
        if (filters) {
            updateFilter();
        }
    }, [filters?.orderChart]);
      

    const getChartByTypeHorizontal = () => {
        return <ChartOptions typeChart={filters?.typeChart} optionChart={filters?.option} options={getChartOptionsHorizontal(filters?.option, filters?.orientation, animations, handleClickRequest)} data={data} />
    };

    const getChartByTypeVertical = () => {
        return <ChartOptions typeChart={filters?.typeChart} optionChart={filters?.option} options={optionsVertical(filters?.option, filters?.orientation, animations, handleClickRequest)} data={data} />
    };

    const LabelFilter  = useMemo(() => {
        if(filters?.option === 1 || filters?.option === 2 ){
            return (filters?.month) ? `${months[filters?.month -1].name}, ${filters?.year}` : 'Todos';
        } else {
            return `${dayjs(filters?.InitialDate).format('DD/MM/YYYY')} a ${dayjs(filters?.FinalDate).format('DD/MM/YYYY')}`
        }
    }, [changed]);  

    const ChartHorizontal = useMemo(() => {
        return getChartByTypeHorizontal();
    }, [changed, filters?.option]);  

    const ChartVertical  = useMemo(() => {
        return getChartByTypeVertical();
    }, [changed, filters?.option]);  


    if(window.innerWidth < 500){
        return (
            <Box sx={{width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', mt: 20, userSelect: 'none',}}>
            <Box className="header-atendimento">
              <Typography variant="h4"  color="text.secondary" >
                Dispositivo incompatível.
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Acesse o Sirrus Atendimento do seu computador para visualizar os gráficos disponíveis.
              </Typography>
            </Box>
              <div >
                <img width={'100%'}  src="https://gifs.eco.br/wp-content/uploads/2022/10/gifs-de-graficos-0.gif" alt="Aguarde" />
              </div>
            </Box>
        )
    } else {
    return (    
        <Box sx={{height: '100%', width: '100%', p: 1, overflow: {xs: "scroll", sm: "scroll", md: "hidden", lg: "hidden"} }}> 
            <Box className="" sx={{ p: '2px 4px', display: 'flex',  mb: 2,  }}>
                <Grid container spacing={1} columns={ 12 } sx={{float: "center", width: '100%',}} >
                    <Grid item xs={12} sm={12} md={9} lg={5} xl={5} sx={{mt: 1, }} > 
                        <Paper elevation={3} sx={{width: '100%', p: 1, fontSize: 12}} >
                            <Typography >Gráfico: {optionsReports[(filters?.option - 1)]?.label}</Typography>
                            <Typography>Período: {LabelFilter}</Typography> 
                            <Typography>Usuário: {(filters?.user) ? filters?.userName : 'Todos'}</Typography> 
                            <Typography>Segmento: {(filters?.segment) ? filters?.segment : 'Todos'}</Typography>
                            <Typography>Total: {totalRequests}</Typography> 
                        </Paper>
                    </Grid>  
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{mt: 1, mb: 1}} >
                        <Box sx={{           
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%', 
                                    flexDirection: 'row'
                                }}>
                            <Button
                                variant='outlined'
                                onClick={handleOpenFilter}
                                endIcon={<SettingsIcon />}
                                sx={{ mr: 1,   p: '10px' }}         
                            >   
                                Opções 
                            </Button>
                            {/* <FormControl  sx={{mr: 1, display: (filters?.option === 3) ? 'flex' : 'none'}}>
                                <InputLabel id="orderChart">Ordem</InputLabel>
                                <Select
                                    labelId="orderChart"
                                    label="Ordem"
                                    fullWidth 
                                    name='orderChart'
                                    value={filters?.orderChart} 
                                    // onChange={handleChangeOrderChart}
                                >
                                    <MenuItem value={'asc'}><em>Crescente</em></MenuItem>
                                    <MenuItem value={'desc'}><em>Decrescente </em></MenuItem>
                                </Select>
                            </FormControl>                              */}
                            {/* <Button
                                variant='outlined'
                                onClick={handlerChanged}
                                endIcon={<PublishedWithChangesIcon />}
                                sx={{ mr: 1,   p: '10px' }}
                            
                            >
                                Gerar
                            </Button>   */}
                        </Box>
                    </Grid>  
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{mt: 1, mb: 1}} >
                        </Grid>                   
                </Grid>
            </Box>
            <div className="chartCard">
                <div className="chartBox">
                    { //Pediram para ser uma listagem aos 45, então adaptei aqui
                        (filters?.option === 12) && (
                            <ClientsNotRequests startDate={filters?.InitialDate} endDate={filters?.FinalDate} segment={filters?.segment} handleChanged={handleChanged} changed={changed} />
                        )
                    }
                    <div className="chartContainer">
                        { filters?.option != 12 && (
                                (filters?.orientation === 'y') ? (
                                <Box sx={chartContainerBodyV(filters, labelsCount)}>
                                <Box sx={{height: getHeightChart(filters, labelsCount), overflowY: 'hidden'}}>
                                    {ChartVertical}
                                </Box>                             
                                </Box>
                            ) : 
                            (
                                <Box sx={chartContainerBodyH(filters, labelsCount)}>                    
                                    {ChartHorizontal}
                                </Box>
                            ) 
                        )}
                    </div>
                </div>
            </div>    
        <Dialog open={openConfig} onClose={handleCloseConfig} > 
        <DialogTitle>Opções de Gráficos</DialogTitle>
        <DialogContent> 
        <LoadingFull open={loading} />           
        <Grid container spacing={1} columns={ 12 } sx={{float: "center"}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 1, mb: 1, }} >
                <FormControl fullWidth>
                    <InputLabel id="chart-option">Qual informação deseja visualizar?</InputLabel>
                    <Select
                        labelId="chart-option"
                        label="Qual informação deseja visualizar?"
                        fullWidth 
                        name='option'
                        value={filters?.option} 
                        onChange={handleChangeFilters}
                    >
                        {
                            optionsReports.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.value} - {option.label}</MenuItem>
                            ))
                            
                        }
                        {
                          user?.requests_charts_adm === 'Y' &&  optionsReportsAdm.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.value} - {option.label}</MenuItem>
                        ))
                            
                        }
                    </Select>
                </FormControl>  
            </Grid>              
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display: (filters?.option === 1) ? 'none' : ''}}  >
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DemoContainer components={['DatePicker']} >
                    <DatePicker 
                        label="Data Inicial" 
                        value={dayjs(filters.InitialDate).utc(IsDev)}
                        onChange={(newValue) => handleChangeFiltersDate('InitialDate', newValue)}
                        format="DD/MM/YYYY" 
                        sx={{width: '100%'}}
                    />
                    <DatePicker 
                        label="Data Final" 
                        value={dayjs(filters.FinalDate).utc(IsDev)}
                        onChange={(newValue) => handleChangeFiltersDate('FinalDate', newValue)}
                        format="DD/MM/YYYY" 
                        sx={{width: '100%'}}
                    />               
                    </DemoContainer>         
                </LocalizationProvider>       
            </Grid>             
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{mt: 1, mb: 1, display: (filters?.option === 1) ? '' : 'none'}} >
                <FormControl fullWidth>
                    <InputLabel id="type-chart-period">Mês</InputLabel>
                    <Select
                        labelId="type-chart-period"
                        label="Mês"
                        fullWidth 
                        name='month'
                        value={filters?.month} 
                        onChange={handleChangeFilters}
                    >
                        {months && months.map((month) => { return ( <MenuItem value={month.value}><em>{month.name}</em></MenuItem> )})}     
                    </Select>
                </FormControl>  
            </Grid>  
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{mt: 1, mb: 1, display: (filters?.option === 1) ? '' : 'none'}} >
                <TextField
                    fullWidth
                    label="Ano"
                    type="number"
                    name='year'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={filters?.year}
                    onChange={handleChangeFilters}
                /> 
            </Grid>   
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} sx={{display: (filters?.option === 4 || filters?.option === 5) ? 'none' : ''}}>
                <UserSelect  
                    label="Atendente"
                    value={filters?.user} 
                    setValue={handleChangeUser} 
                    name="user" 
                    formik={null}
                    error={false}
                    helpText={''}
                />
            </Grid>                   
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} sx={{display: (filters?.option === 3) ? '' : 'none'}}>
                <UserGroupSelect  
                    label="Setor"
                    value={filters?.userGroup} 
                    setValue={handleChangeUserGroup} 
                    name="user" 
                    formik={null}
                    error={false}
                    helpText={''}
                />
            </Grid>                   
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
                <FormControl fullWidth>
                    <InputLabel id="type-chart">Tipo de Gráfico</InputLabel>
                    <Select
                        labelId="type-chart"
                        label="Seguimento"
                        fullWidth 
                        name='segment'
                        value={filters?.segment} 
                        onChange={handleChangeFilters}
                    >
                        {
                            segments && segments.map((segment) => { return ( <MenuItem value={segment}><em>{segment}</em></MenuItem> )})
                        }
                    </Select>
                </FormControl>  
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
                <FormControl fullWidth>
                    <InputLabel id="type-chart">Tipo de Gráfico</InputLabel>
                    <Select
                        labelId="type-chart"
                        label="Tipo de Gráfico"
                        fullWidth 
                        name='typeChart'
                        value={filters?.typeChart} 
                        onChange={handleChangeFilters}
                    >
                        <MenuItem value={1}><em>Barras</em></MenuItem>
                        <MenuItem value={2}><em>Linhas</em></MenuItem>
                    </Select>
                </FormControl>  
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
                <TextField
                    id="outlined-number"
                    label="Número de Registros"
                    fullWidth
                    name='limit'
                    value={filters?.limit} 
                    onChange={handleChangeFilters}
                    type="number"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} > 
            <FormControlLabel  control={
                <Checkbox
                    checked={animations}
                    onChange={(e) => setAnimations(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                /> 
                } label="Gráfico Animado" 
            />
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" color={'warning'} onClick={handleCloseConfig}>Fechar</Button>
            <Button variant="outlined" onClick={updateFilter}>Aplicar</Button>
        </DialogActions>        
        </Dialog>
        <RequestsTableModalCharts values={requestsClick} setOpen={setRequestsClickOpen} setOpenRequest={setOpenRequest} changed={changed} setValues={setValues} open={requestsClickOpen} handleChanged={updateFilter}/>
        <RequestAdmin values={values} open={openRequest} setValues={setValues} setOpenRequest={setOpenRequest} changed={changed} setChanged={setChanged} handleChanged={handleChanged}  />
        <Client values={client} open={openClient} setOpen={setOpenClient} />
        </Box>
    )};
}

export default RequestsChartsPage;