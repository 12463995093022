import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import ClientsActualizations from '../systems/clientsActualizations';
import ClienteFinanceDetails from '../finance';
import Choice from '../../../shared/Choice';
import ClientDetails from './details';
import ClientRequestsDetails from '../requestsDetails';
import { useCryptoSessionStorage } from '../../../../hooks/useCryptoSessionStorage';

let url = process.env.REACT_APP_API+ "/client/";

const Client = (props) => {
  const { getData } = useCryptoSessionStorage();
  const user = getData("user") || {};
  const { editData, postData } = useFetchData(url)
  const { ShowMessage } = useShowMessages();
  const [page, setPage] = useState(0);

  const handleChange = (event, newValue) => {
    setPage(newValue);
  };

  const handlerChangeValues = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : value.target.value,
    }));

  };

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleClickSend = async () => {
    handleClose();
    // const res =  (!props.editing) ? await postData(url, props.values) : await editData(url, props.values);
    // if(res.success){
    //   ShowMessage('success', res.msg);
    //   props.handleChanged();
    //   handleClose();
    // }else{

    //   ShowMessage('error', res.msg);

    // }
  }

  const handleChangeChecks = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : (value.target.checked) ? 'Y' : 'N',
    }));
  }

  return (
    <div>  
      <Dialog open={props.open} >
        <DialogTitle >Cadastro de Cliente</DialogTitle>
        <DialogContent sx={{minHeight: '70vh'}}>
          <Tabs
            value={page}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Cadastro de Clientes"
            variant="fullWidth"
            sx={{mb: 1}}
          >
            <Tab label="Dados"/>
            <Tab label="Sistemas" disabled={false} sx={{display: user?.administration_clients_systems === 'Y' ? 'block' : 'none'}}/>
            <Tab label="Financeiro" disabled={false} sx={{display: user?.administration_clients_finance === 'Y' ? 'block' : 'none'}}/>
            <Tab label="Atendimentos" disabled={false} sx={{display: user?.administration_clients_requests === 'Y' ? 'block' : 'none'}}/>
          </Tabs>
          <Choice.When  condition={page === 0} >
            <ClientDetails values={props.values} />            
          </Choice.When> 
          <Choice.When condition={page === 1} >
            <ClientsActualizations client={props.values}/>
          </Choice.When>
          <Choice.When condition={page === 2}>
            <ClienteFinanceDetails client={props.values?.id_gerente}/>
          </Choice.When> 
          <Choice.When condition={page === 3}>
            <ClientRequestsDetails client={props.values?.id}/>
          </Choice.When> 
        </DialogContent>
        <DialogActions sx={{paddingRight: 4}}>
          <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
          <Button onClick={() => handleClickSend()} variant="outlined" color="success">Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Client;