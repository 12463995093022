import React, {  useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {  Autocomplete, Box, Checkbox, Chip, Collapse, Divider,  FormControl,  FormControlLabel,  Grid,  IconButton,  InputAdornment,  InputLabel,  MenuItem,  OutlinedInput,  Select,  Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputMask from 'react-input-mask';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import useClientSelection from '../../../../hooks/useClientSelection/useClientSelection';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';
import { useDebounce } from '../../../../hooks/useDebounce/useDebounce';
import ReasonsRequestSelect from '../../../shared/ReasonsRequestSelect';
import ChipPubsByReason from '../../../helpdesk/publications/ChipPubsByReason';
import SystemSelect from '../../../shared/SystemSelect';


moment.tz.setDefault('America/Manaus');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');

const url = process.env.REACT_APP_API+ "/requests";
let urlTypeServices = process.env.REACT_APP_API+ "/requests/typeServices";
const rangeTimes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];


export default function RequestClient(props) {
  const { ShowMessage } = useShowMessages();
  const { selectedClient, openModal, modal, setSelectedClient } = useClientSelection(() => {});
  const { data : TypeServices} = useFetchData(urlTypeServices);
  const [errors, setErrors] = useState({});
  const [clientPriority, setClientPriority] = useState(false);
  const { debounce } = useDebounce();
  const { postData, loading } = useFetchData(url);
  const [segment, setSegment] = useState('');
  
  debounce((1*1000), () => {
    setClientPriority(!clientPriority);
  });
  
  const handleChangeErrors = (err, state) => {
    setErrors((prevErrors) => ({
        ...prevErrors,
        [err]: state
      }));
  };


  function onlyNumbers(str) {

    var numbers = str.replace(/[^0-9]/g, '');
    
    return numbers;
  }

  const handleChangeValues = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
   
  }; 

  const handleChangeChecks = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : (value.target.checked) ? 'Y' : 'N',
    }));
  }

  const handleCancel = () => {
    props.setValues({});
    setSelectedClient({});
    handleClose();
    if(props.admin){
      setSelectedClient({});
    }

  }
  const handleClose = () => {
    props.setOpen(false);
  };

  const selectClient = () => {
    openModal();
  }

  const changeTimeToStart = (value) => {
    handlerChangeStartDate(value)
  }

  const handleClickSend = async () => {

    if(props.admin && !props.values?.id_reason){
      handleChangeErrors('id_reason', true);
      return false;
    };

    if(props.admin && !props.values?.system){
      handleChangeErrors('system', true);
      return false;
    } 


    if(!selectedClient?.id){
      ShowMessage('error', 'Informe corretamente o cliente.');
      return;
    }
    if(!props.values?.description || props.values?.description.length < 5){
      ShowMessage('error', 'Informe a descrição da solicitação.');
      return;
    }

    if(!props.admin &&  !props.values?.phone_number || !props.admin && onlyNumbers(props.values?.phone_number).length < 10){
      ShowMessage('error', 'Informe corretamente o número para contato.');
      return;
    }


    let request = props.values;
    request.client = selectedClient?.id;
    request.priority = selectedClient.priority;
    request.cpf_cnpj = selectedClient?.cpf_cnpj;
    request.status = (request?.user) ? 2 : 1;
    request.requestReleased = selectedClient?.requestReleased;
    request.start_date = dayjs(request?.start_date).utc(true);
    request.type_request = selectedClient?.type_client;
    request.level =  request.level || 1;
    request.system = request.system || null;

    await postData(url, request).then((res) => {
      if(res.success){
        ShowMessage('success', 
          'Atendimento Aberto com Sucesso', 
          `Em breve nossa equipe entrará em contato. 
          Acompanhe o andamento pela tela de atendimentos.`);
        if(props.requestsPage) props.handleChanged();
        if(props.admin){
          setSelectedClient({});
        }
        props.setValues({})
        handleClose();
        
      }else{
          ShowMessage('warning', 'Ops, algo deu errado',
          "Por favor, nos informe se o problema persistir");
    }
  })
  }

  const selectTime = (time) => {
    const now = dayjs.tz().format();
    const start_date = moment(now).add(time, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    changeTimeToStart(dayjs.tz(start_date));
  }

  const handleChangeTypeService = (values) => {

    props.setValues((prevValues) => (
      {
          ...prevValues, "type_service": (!values) ? null : values.id,
    }));  
    props.setValues((prevValues) => (
      {
          ...prevValues, "type_service_description": (!values) ? '' : values.description,
    }));   
  }

  const handleChangeReason = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      'id_reason': value?.id || null
    }));
    props.setValues((prevValues) => ({
      ...prevValues, 
      'level': value?.level || 1
    }));

    handleChangeErrors('id_reason', !value);
  }

  const handlerChangeStartDate= (value) => {  
    props.setValues((prevValues) => ({
      ...prevValues, 
      'start_date' : dayjs(value)
    }));
  };
  
  const handleChangeSystem = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      'system': value?.id || null
    }));
    handleChangeErrors('system', !value?.id);
    if(value?.id){
      value?.type && value?.type === 'Ponto' ? setSegment('Ponto') : setSegment('Automação');
    }
  }

  return (
    <div>
      {modal}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Abertura de Atendimento</DialogTitle>
        <DialogContent>
          <form >
            <Box sx={{textAlign: 'center', mb: 1, color: '#f44336', 
                          display: (props.admin && selectedClient?.priority === 2) ? 'flex' : 'none', 
                          flexDirection: 'row',
                          justifyContent: 'center', minHeight: 38.5,
                          border: '2px solid red'}}>
              <Typography
                sx={{
                  fontSize: 23,
                  display: (clientPriority) ? '' : 'none'
                }}
              >
                Cliente Preferencial!
              </Typography>
            </Box>    
            <DialogContentText sx={{display: (props.admin) ? 'none' : '' }} >
              Procure descrever sua solicitação de forma clara, nossa equipe entrará em contato assim que recebermos sua solicitação
            </DialogContentText>
            <Box sx={{ '& .MuiTextField-root': { mt: 2, }, }}>
              <FormControl sx={{ width: '100%', mt: 2, display: (props.admin) ? '' : 'none' }} variant="outlined">
                <InputLabel >Cliente</InputLabel>
                <OutlinedInput
                  defaultValue={' '}
                  label='Cliente'
                  type={'text'}
                  value={selectedClient?.razao_social}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton   
                        onClick={selectClient}
                        edge="end"
                      >
                        <SearchIcon/>
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
                  <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: (props.admin) ? '' : 'none' }}>
                    <SystemSelect  
                      value={props?.values?.system} 
                      setValue={handleChangeSystem} 
                      name="system" 
                      error={errors?.system}
                      helpText={errors?.system && "Selecione o sistema"}
                    />
                  </Grid>
                  {props.admin && 
                    <ReasonsRequestSelect 
                    name='id_reason' 
                    segment={segment}
                    setValue={handleChangeReason} 
                    value={props.values?.id_reason}                     
                    error={errors?.id_reason}
                    helperText={errors?.id_reason && "Informe o motivo da solicitação"} 
                  />
                  }
                  <ChipPubsByReason id_reason={props.values?.id_reason} />
                  <TextField
                    required
                      label="Descrição da solicitação"
                      multiline
                      fullWidth  
                      name='description'
                      defaultValue={props.values?.description}
                      onBlur={handleChangeValues}
                      placeholder='Descreve sua solicitação detalhadamente'           
                  />
                  <Divider sx={{m: 1}}/>
                  <Typography fontSize={15}  >Informações para contato</Typography>
                  <Grid container spacing={1} columns={ 2 } sx={{float: "center",}} >
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1} >
                      <TextField 
                            id="filled-basic"  
                            label="Falar com:" 
                            variant="outlined" 
                            name='contact'
                            defaultValue={props.values?.contato}
                            onBlur={handleChangeValues}
                            fullWidth

                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1} >
                    <InputMask
                        mask="(99) 99999-9999"
                        disabled={false}
                        maskChar=""
                        onBlur={handleChangeValues}
                        defaultValue={props.values?.phone_number}
                        fullWidth
                      >
                        {() =>                     
                        <TextField 
                          id="filled-basic"  
                          label="Número para contato" 
                          variant="outlined"  
                          name='phone_number'
                          fullWidth
                          error={!props.admin &&  errors?.phone_number}
                          helperText={!props.admin &&  errors?.phone_number && "Informe corretamente o número para contato"} 
                        >
                        </TextField> }
                      </InputMask> 
                    </Grid>
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: (props.admin) ? '' : 'none' }}>
                      <Autocomplete
                            disablePortal
                            fullWidth
                            options={TypeServices}
                            freeSolo={true}
                            getOptionLabel={ (option) => option['description'] || option }    
                            value={props.values?.type_service_description}
                            onChange={(event, values) => handleChangeTypeService(values)}
                            renderInput={(params) => 
                            <TextField
                              name="type_service"
                              {...params} label="Tipo de Serviço" 
                            />}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'left', display: (props.admin) ? '' : 'none'}}>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              name={'hide_panel'}
                              checked={props.values?.hide_panel === 'Y'} 
                              onChange={handleChangeChecks}
                              inputProps={{ 'aria-label': 'controlled' }} 
                              
                            />
                          }
                          label='Ocultar do Painel'
                        /> 
                      </Grid> 
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} marginBottom={1} sx={{display: (props.admin) ? '' : 'none'}}>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                      <MobileDateTimePicker 
                        fullWidth
                        sx={{width: '100%', mt: 2}}
                        label={'Previsão para Atendimento'} 
                        ampm={false} 
                        openTo="hours" 
                        name="start_date"
                        value={dayjs.tz(props.values?.start_date)}
                        format="DD/MM/YYYY HH:mm"   
                        onChange={handlerChangeStartDate}
                      />
                    </LocalizationProvider>   
                    <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, display: (props.admin) ? '' : 'none' }}>
                      {rangeTimes.map((item) =>(
                        <Chip 
                          key={item}
                          label={item} 
                          variant="outlined" 
                          onClick={(e) => {selectTime(item)}} 
                        />   
                      ))}  
                    </Box>                 
                    </Grid>                  
                  </Grid>
              </Box> 
            </form> 
        </DialogContent>
        <DialogActions>
          <Button  variant='outlined' color='warning' onClick={handleCancel}>Cancelar</Button>
          <Button  variant='outlined' color='success' disabled={loading} onClick={handleClickSend}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}