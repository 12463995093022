import SearchAndSelect from "../SearchAndSelect";
import { systemsFn } from "../../../services/systems";
import { useQuery } from "react-query"

const SystemSelect = ({ formik, name, setValue, value, error, helpText }) => {
    const { data, isLoading } = useQuery("systems-list", () => systemsFn())
    const systems = data?.data?.map((i) => ({ label: i.description, value: i.id }));

    const handleSelectOption = async (id) => {
        const item = await data?.data?.find((i) => i?.id === id);
        setValue(item);
    };
    
    return <SearchAndSelect 
                name={name} 
                setValue={handleSelectOption} 
                value={value} 
                placeholder="Selecione uma opção" 
                label={"Sistema"} 
                options={systems || []} 
                formik={formik} 
                loading={isLoading} 
                fullWidth
                className="mb-2"
                error={error}
                helpText={helpText}
            />
};
  

export default SystemSelect;