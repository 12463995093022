import moment from "moment"
import { useQuery } from "react-query"
import { clientsSalesFn } from "../../../../services/clients"
import CustomDiv from "../../../shared/CustomDiv"
import { CustomTable } from "../../../shared/Table"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from "@mui/material"
import ClientSalesProductsModal from "./salesProducts/modal"
import { useState } from "react"

const ClientesSalesTable = ({client}) => {
  const { data: sales, isLoading } = useQuery(["clientes-sales", client], () => clientsSalesFn({ id: client }));
  const [openModal, setOpenModal] = useState(false);
  const [sale, setSale] = useState(0);

  const tableHead = [
    { id: "id", headItem: "Nº" },
    { id: "data", headItem: "Data" },
    { id: "total", headItem: "Valor" },
    { id: "action", headItem: "Produtos" },
  ]

  const createData = (
    id, 
    data, 
    total, 
    action
  ) => ({
    id,
    data: moment(data).format("DD/MM/YYYY"),
    total: total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
    action
  })

  const handleOpenSale = (sale) => {
    setSale(sale);
    setOpenModal(true);
  }

  const rows = sales?.map((sale) => {
    return createData(
      sale.NOVENDA, 
      sale.DATA, 
      sale.T_LIQUIDO, 
      <IconButton onClick={() => handleOpenSale(sale?.NOVENDA)} >
        <VisibilityIcon />
      </IconButton>
    )
  });

  return (
    <>
      <CustomDiv className="flex flex-col w-full !p-0">
        <CustomTable
          tableHead={tableHead}
          tableBody={rows}
          isLoading={isLoading}
        />
        <ClientSalesProductsModal open={openModal} setOpen={setOpenModal} sale={sale} />
      </CustomDiv>
    </>
  )
}

export default ClientesSalesTable
