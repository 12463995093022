export const getWidthChart = (filters: { option: number; user: number }, labelsCount: number) => {
  if (
    filters?.option === 1 ||
    filters?.option === 2 ||
    filters?.option === 5 ||
    (filters?.option === 3 && labelsCount <= 31) ||
    (filters?.option === 4 && labelsCount <= 31) ||
    (filters?.option === 5 && labelsCount <= 31)
  ) {
    if (filters?.option === 5 && labelsCount > 30) {
      return `calc((97vw / 31) * ${labelsCount})`;
    } else {
      return '98vw';
    }
  } else {
    // return `calc((97vw / 31) * ${labelsCount})`;
    // if (filters?.user === 0 && filters?.option != 6) {
    //   return `calc((90vw / 5) * ${labelsCount})`;
    // } else if (labelsCount > 31) {
    //   return `calc((97vw / 31) * ${labelsCount})`;
    // }
  }
};

export const getHeightChart = (filters: { option: number; user: number }, labelsCount: number) => {
  const { option, user } = filters;

  if ((option === 3) || (labelsCount <= 10)) {
    if (option === 5 && labelsCount > 30) {
      return `calc((80vh / 30) * ${labelsCount})`;
    } else if (option === 4 || option === 5 || option === 6 || option === 7) {
      return `calc(80vh - 80px)`;
    } else {
      return `calc((80vh / 20) * ${labelsCount})`;
    }
  } else {
    if (user > 0) {
      if (labelsCount <= 15) {
        return 'calc(82vh - 80px)';
      } else {
        return `calc((82vh / 20) * ${labelsCount})`;
      }
    } else {
      return `calc((82vh / 5) * ${labelsCount})`;
    }
  }
};

export const getLegendPosition = (option: number, orientation: string) => {

  let position = 'top';
  let align = 'center';

  if (option > 2 || orientation === 'y') {
    align = 'start';
  }
  return {
    display: true,
    position: position,
    align: align,
  };
};
